import { defineComponent, inject, onMounted, provide, reactive } from 'vue';

import * as env from '@resn/gozer-env';

const EnvKey = 'env';

export const useEnv = () => {
    const env = inject(EnvKey);
    if (!env) {
        throw new Error('useEnv must be used within a EnvProvider');
    }
    return env;
};

const getEnvValues = (isClient = false) => {
    // const isClient = process.client;
    return {
        isIE: isClient ? env.isIE : null,
        isChrome: isClient ? env.isChrome : null,
        isEdge: isClient ? env.isEdge : null,
        isFirefox: isClient ? env.isFirefox : null,
        isSafari: isClient ? env.isSafari : null,
        isAndroid: isClient ? env.isAndroid : null,
        isIOS: isClient ? env.isIOS : null,
        isIpadPro: isClient ? env.isIpadPro : null,
        isNotchIPhone: isClient ? env.isNotchIPhone : null,
        isMobile: isClient ? env.isMobile : null,
        isDesktop: isClient ? !env.isMobile : null,
        isHandheld: isClient ? env.isHandheld : null,
        isTablet: isClient ? env.isTablet : null,
        hasTouch: isClient ? env.hasTouch : null,

        // functions
        iEVersion: isClient ? env.getIEVersion() : null,
        chromeVersion: isClient ? env.getChromeVersion() : null,
        firefoxVersion: isClient ? env.getFirefoxVersion() : null,
        iOSVersion: isClient ? env.getIOSVersion() : null,
        safariVersion: isClient ? env.getSafariVersion() : null,
    };
};

const EnvProvider = defineComponent({
    setup() {
        const envProvider = reactive({
            ...getEnvValues(),
        });

        provide(EnvKey, envProvider);

        onMounted(() => {
            envProvider.device = env.isMobile ? 'mobile' : 'desktop';

            Object.assign(envProvider, {
                ...getEnvValues(true),
            });
        });
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default EnvProvider;
