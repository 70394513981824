<template>
    <EnvProvider>
        <DeviceLayoutProvider :mobileThreshold="VIDEO_SMALL">
            <GsapProvider>
                <MediaMetaProvider :data="mediaMetaData">
                    <FanaticsProvider>
                        <ColorPaletteProvider>
                            <AudioProvider>
                                <StateManager>
                                    <FontLoaderProvider>
                                        <SmoothScrollLenis :paused="store.scrollPaused">
                                            <slot />
                                        </SmoothScrollLenis>
                                    </FontLoaderProvider>
                                </StateManager>
                            </AudioProvider>
                        </ColorPaletteProvider>
                    </FanaticsProvider>
                </MediaMetaProvider>
            </GsapProvider>
        </DeviceLayoutProvider>
    </EnvProvider>
</template>

<script setup>
    import { DeviceLayoutProvider, MediaMetaProvider, SmoothScrollLenis } from '@resn/gozer-vue';

    import AudioProvider from './AudioProvider';
    import ColorPaletteProvider from './ColorPaletteProvider';
    import EnvProvider from './EnvProvider';
    import FanaticsProvider from './FanaticsProvider';
    import FontLoaderProvider from './FontLoaderProvider';
    import GsapProvider from './gsap/GsapProvider.vue';
    import StateManager from './StateManager';
    import { VIDEO_SMALL } from '~/core/constants';
    import mediaMetaData from '~/media-metadata.json';
    import { useAppStore } from '~/stores/app';

    const store = useAppStore();
</script>

<style lang="scss">
    @import '@/styles/critical.scss';
    @import '@/styles/main.scss';
</style>
