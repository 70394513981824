import { computed, defineComponent, inject, provide, ref } from 'vue';

export const FontLoaderKey = 'FontLoader';

export const useFontLoader = () => {
    const fontLoader = inject(FontLoaderKey);

    if (!fontLoader) {
        throw new Error('useFontLoader must be used within a FontLoaderProvider');
    }

    return fontLoader;
};

const FontLoaderProvider = defineComponent({
    setup() {
        const CustomFont1 = ref(false);
        const CustomFont2 = ref(false);

        const allFontsLoaded = computed(() => CustomFont1.value && CustomFont2.value);

        // loadFont({ fontface: 'CustomFont', weight: 500 })
        //     .then(() => (CustomFont1.value = true))
        //     .catch((err) => console.error('CustomFont', err));

        // loadFont({ fontface: 'CustomFont2', weight: 500 })
        //     .then(() => (CustomFont2.value = true))
        //     .catch((err) => console.error('CustomFont2', err));

        provide(FontLoaderKey, {
            CustomFont1,
            CustomFont2,
            allFontsLoaded,
        });
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default FontLoaderProvider;
