<script setup>
    import { onMounted, provide } from 'vue';

    import { gsap } from '@resn/gsap';
    import { CustomEase } from '@resn/gsap/CustomEase';
    import { CustomWiggle } from '@resn/gsap/CustomWiggle';
    import { ScrollTrigger } from '@resn/gsap/ScrollTrigger';

    const isClient = import.meta.client;

    if (isClient) {
        provide('gsap', { ScrollTrigger, CustomWiggle, CustomEase });
    }

    gsap.registerPlugin(ScrollTrigger, CustomEase, CustomWiggle);
    ScrollTrigger.config({
        ignoreMobileResize: true,
        limitCallbacks: false,
        // autoRefreshEvents: "visibilitychange,DOMContentLoaded,load",
    });

    onMounted(() => {
        if (isClient) {
            window.ScrollTrigger = ScrollTrigger;
        }
    });
</script>

<template>
    <slot />
</template>
